@tailwind base;
@tailwind components;
@tailwind utilities;

.paper {
  border-radius: 0px;
  background: linear-gradient(225deg, #ffffff, #cacaca);
  box-shadow:
    -30px 30px 50px #aaaaaa,
    30px -30px 50px #ffffff;
  margin-left: auto;
  margin-right: auto;
  width: 210mm;
  height: 297mm;
  @apply bg-gradient-to-br from-gray-200 to-gray-400;
  @apply border border-gray-400 shadow-2xl;
}
